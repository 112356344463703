import React from "react";
import Navbar from "../components/navbar";
import Image from "../components/image";
import img from "../images/about-svg.svg";
import Seo from "../components/seo";
import Heading from "../components/heading/heading";
import Footer from "../components/footer/Footer";

function AboutUs() {
  const imgStyles = {
    boxShadow: "-9px 9px 0px #DE0646",
    height: "440px",
    width: "70%",
  };

  const props = {
    source: img,
    styling: imgStyles,
  };

  return (
    <>
      <Seo title="About Us" />
      <Navbar />
      <Heading>About Us</Heading>
      <div className="flex flex-1 lg:justify-around flex-1 items-center lg:items-start flex-col lg:flex-row lg:mx-36 lg:my-10 my-20">
        <div className="flex justify-center lg:w-1/3 w-full lg:h-full h-3/4">
          <Image {...props} />
        </div>
        <div className="flex flex-col justify-start flex-1 pl-7 mx-10 mt-10 lg:mt-0 w-2/3 lg:text-2xl text-1xl text-white h-full">
          <p>
            DuskSec is a cybersecurity start-up focused on providing top of the
            line penetration testing , vulnerability analysis, robust IoT based
            surveillance systems , tail-gating protection system , IT support ,
            web security , security training and compliance services. The
            startup also focuses on providing Block-chain based De-centralized
            solutions which include an ecosystem of applications and immutable
            data integrity solutions.
            Hackers modify the network entity using an arsenal of attacks to
            leave a backdoor in the device to prepare for subsequent
            infiltration and modify the key configuration files, such as
            bypassing the SSH Authentication. The use of Botnets and DDOS
            attacks used by them causes a meltdown of the availability CIA Triad
            resulting in loss and cause damage to the entire network.
          </p>
          <br/>
          <p>
            DuskSec was founded in Srinagar , Jammu and Kashmir with a goal of
            protecting digital assets of individuals , firms and organizations.
            The start-up also provides Data integrity and protection services .
            With combined experience, thousands of hours of practice, and core
            values from our time in service, we will use our skill set to secure
            your environment.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
